@import './formo-ui-build.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary-foreground: var(--base-black);
    --primary-background: 191 239 142;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --discord-DEFAULT: 87 101 242;
    --discord-username: 115 120 255;

    --farcaster-DEFAULT: 134 91 242;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    font-family: var(--font-dm-sans);
    @apply bg-background text-foreground;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: rgb(var(--base-dark-100) / 0.5);
  border-radius: 1000px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(56, 56, 59, 0.5);
  border-radius: 1000px;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ql-editor *,
.ql-editor {
  font-family: inherit;
  padding: 0px !important;
}

.ql-editor p,
.ql-editor p span {
  font-family: inherit;
  color: inherit;
}

.ql-formats {
  margin: 8px 6px !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar {
  width: max-content !important;
}

.quill-toolbar-hidden .ql-toolbar {
  display: none;
}

.ql-toolbar.ql-snow {
  padding: 0px !important;
  position: absolute;
  top: -45px;
  left: 0px;
  z-index: 2;
}

/* Styles for snow theme */
.ql-snow .ql-tooltip {
  padding: 8px 14px;
  border-radius: var(--xs, 6px);
  width: max-content !important;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  display: none;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  display: none;
}

.ql-snow .ql-tooltip input[type='text'] {
  border: none;
  border-radius: var(--xs, 6px);
}

.ql-snow .ql-tooltip input[type='text']:focus {
  outline: none;
  box-shadow: none;
}

.ql-snow.ql-toolbar .ql-formats:first-child {
  width: max-content;
}

.ql-snow.ql-toolbar button {
  margin-right: 6px;
}

.ql-snow.ql-toolbar .ql-formats:last-child {
  margin-right: 0px !important;
}

/* Tooltip of hyperlink for snow theme*/
.ql-snow .ql-tooltip {
  display: flex;
}

.ql-snow .ql-tooltip::before {
  display: none;
}

.ql-snow .ql-tooltip a.ql-preview {
  color: var(--base-dark-400, #47474c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 6px !important;
}

.ql-snow .ql-tooltip.ql-editing input[type='text'] {
  border: none;
  padding: 0px;
  content: '' !important;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action {
  display: none !important;
}

.ql-snow .ql-tooltip a.ql-action {
  display: flex;
  align-items: center;
  border-left: 1px solid #ccc;
}

.ql-snow .ql-tooltip a.ql-action::after {
  border: none !important;
  content: '' !important;
  display: inline-block !important;
  width: 21px;
  height: 20px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none"%3E%3Cpath d="M14.1169 3.1267C14.3156 2.92801 14.5514 2.7704 14.811 2.66287C15.0706 2.55534 15.3489 2.5 15.6299 2.5C15.9108 2.5 16.1891 2.55534 16.4487 2.66287C16.7083 2.7704 16.9441 2.92801 17.1428 3.1267C17.3415 3.32538 17.4991 3.56126 17.6067 3.82086C17.7142 4.08045 17.7695 4.35869 17.7695 4.63968C17.7695 4.92066 17.7142 5.1989 17.6067 5.45849C17.4991 5.71809 17.3415 5.95397 17.1428 6.15266L6.93022 16.3653L2.76953 17.5L3.90427 13.3393L14.1169 3.1267Z" stroke="%238E8E92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  background-size: cover;
  margin-left: 6px !important;
}

.ql-snow .ql-tooltip a.ql-remove {
  display: flex;
  align-items: center;
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: '' !important;
  display: inline-block;
  width: 16px;
  height: 17px;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M2.18266 3.61391C2.34629 3.45247 2.57509 3.36084 2.8146 3.36084H13.7237C13.9632 3.36084 14.192 3.45247 14.3556 3.61391C14.5193 3.77536 14.6031 3.99218 14.5872 4.21278L13.8297 14.7038C13.8297 14.7039 13.8297 14.7038 13.8297 14.7038C13.7575 15.7245 12.8854 16.4999 11.754 16.4999H4.7843C3.66651 16.4999 2.77262 15.734 2.70841 14.7009L1.95114 4.21278C1.93521 3.99218 2.01904 3.77536 2.18266 3.61391ZM3.73957 4.95847L4.43632 14.6082C4.44681 14.7849 4.57065 14.9023 4.7843 14.9023H11.754C11.9612 14.9023 12.089 14.7917 12.1026 14.5999L12.7987 4.95847H3.73957Z" fill="%238E8E92"/%3E%3Cpath d="M1.60352 4.15967H14.9368" stroke="%238E8E92" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/%3E%3Cpath d="M5.29883 3.90865V2.40325C5.2984 2.28453 5.32693 2.16692 5.38278 2.05718C5.43863 1.94744 5.52069 1.84773 5.62425 1.76378C5.72781 1.67984 5.85082 1.61332 5.98621 1.56805C6.12159 1.52278 6.26669 1.49966 6.41314 1.5H10.1275C10.274 1.49966 10.419 1.52278 10.5544 1.56805C10.6898 1.61332 10.8128 1.67984 10.9164 1.76378C11.0199 1.84773 11.102 1.94744 11.1579 2.05718C11.2137 2.16692 11.2422 2.28453 11.2418 2.40325V3.90865" stroke="%238E8E92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M6.34375 7.55225L6.64678 12.5787" stroke="%238E8E92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cpath d="M10.1976 7.55225L9.89453 12.5787" stroke="%238E8E92" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
  background-size: cover;
  margin-left: 6px !important;
}

/* Styles for bubble theme */
.ql-bubble * {
  overflow: visible !important;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
  width: max-content;
}

.ql-bubble .ql-toolbar .ql-formats button {
  margin-right: 6px;
}

.ql-bubble .ql-toolbar .ql-formats:last-child {
  margin-right: 0px !important;
}

.ql-tooltip {
  width: auto !important;
  z-index: 1;
}

.ql-editing {
  width: inherit !important;
}

.ql-tooltip-arrow {
  display: none !important;
}

.ql-toolbar {
  width: inherit;
  border-radius: var(--xs, 6px);
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.06);
}

.ql-bubble .ql-tooltip {
  border-radius: var(--xs, 6px) !important;
  background-color: white !important;
  color: rgba(71, 71, 76, 1) !important;
}

.ql-tooltip.ql-editing {
  left: auto !important;
}

.ql-active {
  color: rgba(71, 71, 76, 1) !important;
  border-radius: var(--radius-XS, 4px) !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  display: flex !important;
  padding: 4px !important;
  align-items: center;
  justify-content: center;
}

.ql-bold:hover,
.ql-italic:hover,
.ql-link:hover {
  border-radius: var(--radius-XS, 4px) !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

.ql-bubble .ql-tooltip-editor input[type='text'] {
  color: black !important;
  box-shadow: none;
}

.ql-bubble .ql-tooltip-editor a {
  top: 5px !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a:not(.ql-close):hover::before,
.ql-container.ql-bubble:not(.ql-disabled) a:not(.ql-close):hover::after,
.ql-container.ql-bubble:not(.ql-disabled) a:not(.ql-close)::before {
  text-overflow: ellipsis;
  max-width: 180px;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: none;
  overflow: hidden !important;
  color: var(--base-dark-400, #47474c);
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
}

/* Authkit Fascaster Sign In button */
.fc-authkit-signin-button,
.fc-authkit-signin-button button {
  height: 48px;
}

.fc-authkit-signin-button button {
  padding: 8px 16px;
  background-color: rgba(134, 91, 242, 1);
  border: 1.5px solid rgba(236, 236, 236, 0.3);
}

/* Override React-share buttons */
.react-share__ShareButton {
  opacity: unset !important;
  @apply rounded-full;
}

/* Shiki highlighter */
pre.shiki {
  border-radius: 4px;
  padding: 16px;
}

pre.shiki code {
  white-space: pre-wrap;
  font-family: var(--font-ibm-plex-mono) !important;
  font-size: 12px !important;
  display: inline-block;
  word-break: break-all;
}

.show-line-numbers pre.shiki code {
  counter-reset: step;
  counter-increment: step 0;
}

.show-line-numbers pre.shiki code .line::before {
  content: counter(step);
  counter-increment: step;
  width: 1rem;
  margin-right: 1.5rem;
  display: inline-block;
  text-align: right;
  color: rgba(115, 138, 148, 0.4);
}
